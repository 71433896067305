@use 'src/styles/theming' as *;

@import 'node_modules/bootstrap/scss/bootstrap-reboot';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/grid';

@import 'theme';

html {
  font-size: 16px;
  line-height: 16px;
  position: relative;
}

body {
  background: map-get($gray-map, 10);
  color: map-get($gray-map, 999);
  font-family: 'Hind Vadodara', sans-serif;
  height: 100%;
  line-height: 1rem;

  &.open-form {
    height: 0;
    min-height: 0;
    overflow: hidden;
  }

  input[type='text'],
  textarea {
    color: map-get($gray-map, 500);

    &:focus {
      color: map-get($gray-map, 900);
    }
  }
}

.h-100 {
  height: 100%;
}

.native-scroll {
  overflow: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 24px;
    width: 5px;
  }
}

.mdc-dialog {
  mat-dialog-content {
    &.mdc-dialog__content {
      padding: 6px;
    }
  }
}
