@use 'styles/theming' as *;
@use '@angular/material' as mat;

@include mat.core();

@include mat.core-theme($theme);
@include mat.all-component-themes($theme);

$team-chat-typography: mat.m2-define-typography-config(
  $font-family: 'Hind Vadodara, sans-serif;',
  $button: mat.m2-define-typography-level(12px, 12px, 500)
);

@include mat.all-component-typographies($team-chat-typography);

input[type='text'] {
  border: 1px solid map-get($gray-map, 75);
  border-radius: 4px;

  &:focus {
    border-color: map-get($custom-map, di-old-hover);
  }
}

.mdc-form-field > label {
  margin-bottom: 0;
}

.mdc-snackbar.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background-color: map-get($di-primary-map, 700);
  }

  .mat-mdc-simple-snack-bar {
    .mat-mdc-snack-bar-label {
      color: map-get($gray-map, 2);
      font-size: 0.75rem;
    }
  }
}
